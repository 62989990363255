import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image';
import React, { useState } from 'react'
import Form from '../education-form';
const popins=Poppins({weight:['500','600','700'],subsets:['latin']});
// const quicksand=Quicksand({weight:['500','600','700'],subsets:['latin']});
export default function CourseStartFrom() {
    const [form,setForm]= useState(false);

    const FormPopup = ()=>{
      setForm(true);
    }
    
    const CloseFormPopup = ()=>{
      setForm(false);
    }
    
  return (
  <section className='bg-clr pdtb70 mpdtb40 '>
    <div className='container'>
    <div className='df fac fjc fjse fw'>
        <div className=''>
        <h3 className={`fs-32 mfs-18 ${popins.className}`}>“Kickstart Your Tech Career Today”</h3>
        <h4 className={`fs-28 mfs-16 mt32 mmt16 tac ${popins.className}`}>Courses Price Starts from </h4>
        <h5 className={`ylw-clr tac fs-28 mfs-16 mt12 ${popins.className}`}> ₹5,000 to 2,00,000 </h5>
        <div className='df fjc'>
        <button onClick={FormPopup} className='enrl-btn fs-16 mfs-14 pdtb10 cw mt16 cursor '>Book a Free Demo</button>
        </div>
        </div>
        <Image className='mdn' src={"/home-v2-images/course-price.png"} alt='Course Starting Price'  width={612} height={376} loading='lazy'/>
        <Image className='dn mdb mt24' src={"/home-v2-images/course-price.png"} alt='Course Starting Price'  style={{width:"100%",height:"100%"}} width={612} height={376} loading='lazy'/>

    </div>
    </div>
    {form &&<Form close={CloseFormPopup}/> }
    <style jsx>
        {`
       .bg-clr{
               background-image: url('/home-v2-images/benefits-bg.webp');
// background: linear-gradient(90deg, #FFDD99 0%, #FFDD99 10%, #FFFFFF 80%, #FFFFFF 100%);


            width:100%;
            height: auto;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
           
            background-size: 100% 100%;
       } 
    .enrl-btn{
        background-color: #FDBB39;
        border-radius: 8px;
       width: 230px;
       border:none;

        }
        `}
    </style>
  </section>

  )
}
