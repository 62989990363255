import Layout from "../component/layout";
import TestimonialSlide from "../component/Testimonial-Slide";
import NextSeoModule from "@/component/next-seo";
import { Poppins } from 'next/font/google'
import Head from "next/head";
import TrendingCourses from "@/component/coursetemplate/trending-courses";
import HeroSection from "@/component/home-page-v2/hero-section";
import BestInstitute from "@/component/home-page-v2/best-institute";
import BenefitsOfJoining from "@/component/home-page-v2/benefits-of-joining";
import BestItTraining from "@/component/home-page-v2/best-it-training";
import HomeBlogCards from "@/component/blog-v2.js/home-blog-cards";
import CourseStartFrom from "@/component/home-page-v2/course-start-from";


const popins = Poppins({weight:"500",subsets:['latin']})
export default function Home() {

  const TrendingCoursesList = [
    { id: "1", path: "aws", courseName: "AWS",rating:'4.9',students:'985',durations:"45",  },
    { id: "2", path: "azure", courseName: "AZURE",rating:'4.9',students:"735",durations:"45" },
    { id: "3", path: "devops", courseName: "DEVOPS", students: "569",rating:"4.7",durations:"45"  },
    { id: "4", path: "azure-data-engineering", courseName: "AZURE DATA ENGINEERING",students: "585",rating:"4.8",durations:"45" },
    { id: "5", path: "powerapps", courseName: "POWER APPS",students: "785",rating:"4.5",durations:"45"},
    { id: "6", path: "flutter", courseName: "FLUTTER",students: "735",rating:"4.9",durations:"45"},

  ];

  const TestiData =[  
    {
      img:"/testimonial-img/alex.webp",
      review:"I had completed AWS course.I learn lot of new knowledge not only for AWS but also other from Ghani Sir.Thank for excellent training given by you",
      UserName: "Umar Farook",
      Course: "AWS DevOps",
    },
    
    {
      img:"/testimonial-img/alex.webp",
      review:"I have completed my full stack development course in learnsoft.org institute. They are very helpful for us to gain technical knowledge.and they are providing internship it is very helpful to improve our skills",
      UserName: "Mohamed",
      Course: "Full Stack Development",
    },
      
    {
      img:"/testimonial-img/alex.webp",
      review:"I am from pune. On first I was afraid to learn the course in online. later I had a demo session on Azure. From the demo to till my whole course completion I had an amazing experience thanks to ghani",
      UserName: "Nishanthini Praveena",
      Course: "Azure DevOps",
    },
    {
      img:"/testimonial-img/alex.webp",
      review:"I had completed  flutter course at Learnsoft academy. The training was so good and they taught me well and gave a lot of career guidance, Thank you team.",
      UserName: "Pavi Sparrow",
      Course: "Flutter",
    },
    {
      img:"/testimonial-img/alex.webp",
      review:
        "Overall PP training was good.Trainer is knowledgeable and we now have recorded sessions available too.Trainer has provided some good real time examples related to IT environment which helped me too.",
      UserName: "Bala Ganesh",
      Course: "Power Platform",
    }, 
    {
      img:"/testimonial-img/alex.webp",
      review:
        "I did azure DevOps course here . Had a wonderful experience with great learning experience. Even i got a placement also with the help of them . Now i am working at a good company with decent package. I strongly recommend learnsoft for learning software related courses thanks to learnsoft",
      UserName: "Bala Chandhar",
      Course: "Azure Devops",
    },   
  ]

  return (
    <>
    <Head>
            <meta name="robots" content="index,follow" />
          </Head>
              <NextSeoModule
                title="Best Software Training Institute in chennai - learnsoft.org"
                description="learnsoft.org, the leading software training institute in Chennai, provides industry-oriented courses in DevOps, cloud computing, Salesforce, programming, data engineering, web development, software testing and more with job placement opportunities."
                url=""
                img="logob.webp"
                keyword="Software training institutes in Chennai, Best software training courses in Chennai, IT training institutes in Chennai, Online software training in Chennai,Best software training institutes in Chennai, Top software training centers in Chennai, Software certification in Chennai, Job-oriented software training in Chennai, Best software training for beginners in Chennai, Software training with placement in Chennai."
                breadcrumbs={"Home"}
              />
    <Layout navbarBackgroundColor="#281E1E">
      {/* <MainSection /> */}
    <HeroSection/>
    <BestItTraining/>
    <div className="mt70 mmt40">
      <TrendingCourses CourseListdata={TrendingCoursesList} hideTitle={true}/>
      </div>
      <CourseStartFrom/>
    <BestInstitute/>
    <BenefitsOfJoining/>
    <HomeBlogCards/>
    <TestimonialSlide TestiData={TestiData}/> 
    </Layout>
    </>
  );
}
